
import { defineComponent, ref } from 'vue';

interface EnumValueInterface {
	enumValue: string;
	id: string;
	sortOrder: number;
}

interface EnumInterface {
	customerId: number;
	enumName: string;
	enumerationValues: EnumValueInterface[];
	id: string;
	sortOrder: number;
}

interface SectionSettingsInterface {
	anchorPoint: string;
	class: string;
	template: string;
	classArray: string[];
	templateArray: string[];
	sortOrder: string;
	cssClasses: EnumInterface;
	cssTemplate: EnumInterface;
}

export default defineComponent({
	name: 'SectionSettings',
	props: ['settingsData'],
	setup(props, { emit }) {
		const data = ref<SectionSettingsInterface>({
			anchorPoint: props.settingsData.anchorPoint || '',
			class: props.settingsData.class || '',
			template: props.settingsData.template || '',
			sortOrder: props.settingsData.sortOrder || 0,
			cssClasses: props.settingsData.cssClasses || {},
			cssTemplate: props.settingsData.cssTemplate || {},
			classArray: [],
			templateArray: []
		});

		if (data.value.class) {
			data.value.classArray = data.value.class.split(',');
		}

		if (data.value.template) {
			data.value.templateArray = data.value.template.split(',');
		}

		const applySettings = () => {
			data.value.class = data.value.classArray?.join(',') || '';
			data.value.template = data.value.templateArray?.join(',') || '';
			emit('applySectionSettings', data.value);
		};

		const deleteSection = () => {
			emit('deleteSection');
		};

		return {
			data,
			applySettings,
			deleteSection
		};
	}
});
