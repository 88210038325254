
import { defineComponent, ref, watch, onMounted } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { VContent } from '@/core/data/videoContent';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
	name: 'video-content-modal',
	props: {
		id: String,
		selectedCustomer: String
	},
	components: {
		Datatable
	},
	setup(props, { emit }) {
		const id = props.id;
		const loading = ref<boolean>(true);
		const paginationHeader = ref<any>(null);
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});
		const videoContentModalRef = ref<null | HTMLElement>(null);

		const tableHeader = ref([
			{
				key: 'checkbox',
				sortable: false
			},
			{
				name: 'Title',
				key: 'title',
				sortable: true
			},
			{
				name: 'Status',
				key: 'published',
				sortable: true
			},
			{
				name: 'Button Label',
				key: 'buttonLabel',
				sortable: true
			},
			{
				name: 'Display Amount',
				key: 'displayAmount',
				sortable: true
			},
			{
				name: 'Publication Date',
				key: 'publicationDate',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);

		watch(
			() => pagingFilteration.value.paging.pageSize,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getPlayList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			(newValue, oldValue) => {
				console.log('newValue', newValue);
				console.log('oldValue', oldValue);
				getPlayList();
			},
			{ deep: true }
		);

		const tableData = ref<Array<VContent>>([]);
		const initMedia = ref<Array<VContent>>([]);

		const getPlayList = () => {
			ApiService.post(`PlayList/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					paginationHeader.value = JSON.parse(headers['x-pagination']);
					// console.log('check', paginationHeader.value);
					tableData.value.splice(0, tableData.value.length, ...data);
					initMedia.value.splice(0, tableData.value.length, ...data);
					loading.value = false;
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const onItemsPerPageChange = itemsPerPage => {
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		const setSelectedVideoContent = row => {
			emit('setSelectedPlayList', row);
			hideModal(videoContentModalRef.value);
		};

		onMounted(() => {
			getPlayList();

			var modelElement = <any>(
				document.getElementById('kt_modal_add_playlist_modal')
			);

			if (modelElement) {
				modelElement.addEventListener('hidden.bs.modal', function (event) {
					emit('modalCloseCallback');
				});
			}
		});

		const search = ref<string>('');

		const searchItems = () => {
			console.log('searchItems:', pagingFilteration.value);
			getPlayList();
		};

		return {
			id,
			moment,
			search,
			loading,
			tableData,
			tableHeader,
			paginationHeader,
			pagingFilteration,
			videoContentModalRef,
			searchItems,
			onCurrentChange,
			onItemsPerPageChange,
			setSelectedVideoContent
		};
	}
});
