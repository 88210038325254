import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered w-75 mw-950px" }
const _hoisted_3 = { class: "modal-content px-4" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "svg-icon svg-icon-1" }
const _hoisted_7 = { class: "card-header border-0 pt-6" }
const _hoisted_8 = { class: "card-title" }
const _hoisted_9 = { class: "svg-icon svg-icon-1 position-absolute ms-6" }
const _hoisted_10 = {
  key: 0,
  class: "card-body pt-0"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    id: _ctx.id || 'kt_modal_select_content',
    ref: "modalRef",
    tabindex: "-1",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, "Select " + _toDisplayString(_ctx.templateType), 1),
          _createElementVNode("button", {
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideImageList && _ctx.hideImageList(...args))),
            class: "btn btn-icon btn-sm btn-active-icon-primary"
          }, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr061.svg" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.searchItems && _ctx.searchItems(...args)), ["prevent"])),
              class: "d-flex align-items-center position-relative my-1"
            }, [
              _createElementVNode("span", _hoisted_9, [
                _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
              ]),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pagingFilteration.filter.searchTerm) = $event)),
                placeholder: "Search",
                class: "form-control form-control-solid w-250px ps-15"
              }, null, 512), [
                [_vModelText, _ctx.pagingFilteration.filter.searchTerm]
              ])
            ], 32)
          ])
        ]),
        (_ctx.tableData.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_Datatable, {
                cursorPointer: true,
                "table-data": _ctx.tableData,
                "table-header": _ctx.tableHeader,
                onOnclickRow: _ctx.handleCallback,
                onCurrentChange: _ctx.onCurrentChange,
                total: _ctx.paginationHeader?.TotalCount,
                "enable-items-per-page-dropdown": true,
                onItemsPerPageChange: _ctx.onItemsPerPageChange,
                rowsPerPage: _ctx.pagingFilteration.paging.pageSize
              }, {
                "cell-displayTitle": _withCtx(({ row: media }) => [
                  _createTextVNode(_toDisplayString(media.templateContentFields.displayTitle), 1)
                ]),
                "cell-contentType": _withCtx(({ row: media }) => [
                  _createTextVNode(_toDisplayString(media.contentType), 1)
                ]),
                "cell-id": _withCtx(({ row: media }) => [
                  _createTextVNode(_toDisplayString(media.id), 1)
                ]),
                "cell-createdDateTime": _withCtx(({ row: media }) => [
                  _createTextVNode(_toDisplayString(_ctx.moment(new Date(media.publicationDate)).format('DD MMM YYYY')), 1)
                ]),
                _: 1
              }, 8, ["table-data", "table-header", "onOnclickRow", "onCurrentChange", "total", "onItemsPerPageChange", "rowsPerPage"])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 8, _hoisted_1))
}