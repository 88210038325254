
import { defineComponent, ref, onMounted, watch } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { IMedia } from '@/core/data/media';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import { reinitializeComponents } from '@/core/plugins/keenthemes';

export default defineComponent({
	name: 'select-cms-media-modal',
	props: {
		selectedCustomer: String
	},
	components: {
		Datatable
	},
	setup(props, { emit, expose }) {
		const selectCMSMediaModalRef = ref<null | HTMLElement>(null);
		const loading = ref<boolean>(false);
		const search = ref<string>('');
		const tableData = ref<Array<IMedia>>([]);

		const paginationHeader = ref<any>({ TotalCount: 0 });
		const pagingFilteration = ref<any>({
			paging: {
				pageNumber: 1,
				pageSize: 10
			},
			filter: {
				searchTerm: ''
			},
			orderBy: {
				orderByField: '',
				orderDesc: false
			}
		});

		const tableHeader = ref([
			{
				name: 'Image',
				key: 'url',
				sortable: true
			},
			{
				name: 'Title',
				key: 'name',
				sortable: true
			},
			{
				name: 'Id',
				key: 'id',
				sortable: true
			},
			{
				name: 'created At',
				key: 'createdDateTime',
				sortable: true
			}
		]);

		watch(
			() => pagingFilteration.value.paging.pageSize,
			(newValue, oldValue) => {
				getMediaList();
			},
			{ deep: true }
		);

		watch(
			() => pagingFilteration.value.paging.pageNumber,
			(newValue, oldValue) => {
				getMediaList();
			},
			{ deep: true }
		);

		const onItemsPerPageChange = itemsPerPage => {
			// console.log('itemsPerPage: ', itemsPerPage);
			pagingFilteration.value.paging.pageSize = itemsPerPage;
		};

		const onCurrentChange = currentPage => {
			// console.log('current page: ', currentPage);
			pagingFilteration.value.paging.pageNumber = currentPage;
		};

		onMounted(() => {
			getMediaList();
		});

		const getMediaList = () => {
			ApiService.post(`CmsMedia/list`, pagingFilteration.value)
				.then(({ data, headers }) => {
					// console.log('getMediaList response: ', data);
					paginationHeader.value = JSON.parse(headers['x-pagination']);

					tableData.value.splice(0, tableData.value.length, ...data);
					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('getMediaList error: ', response);
				});
		};

		const hideImageList = e => {
			e.preventDefault();
			hideModal(selectCMSMediaModalRef.value);
		};

		const getSelectedImage = mediaId => {
			return tableData.value.find(item => item.id == mediaId);
		};

		const setSelectedImage = row => {
			emit('setSelectedImage', row);
			hideModal(selectCMSMediaModalRef.value);
		};

		const searchItems = () => {
			// console.log('searchItems:', pagingFilteration.value);
			getMediaList();
		};

		expose({
			getSelectedImage
		});

		return {
			loading,
			tableData,
			tableHeader,
			selectCMSMediaModalRef,
			moment,
			search,
			searchItems,
			hideImageList,
			setSelectedImage,
			onCurrentChange,
			paginationHeader,
			pagingFilteration,
			onItemsPerPageChange
		};
	}
});
