
import { defineComponent, ref, watch, onMounted } from 'vue';
import { hideModal } from '@/core/helpers/dom';
import { VContent } from '@/core/data/videoContent';
import ApiService from '@/core/services/ApiService';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import moment from 'moment';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import IOverview from '@/core/data/overviews';

export default defineComponent({
	name: 'overviews-modal',
	props: {
		id: String,
		selectedCustomer: String
	},
	components: {
		Datatable
	},
	setup(props, { emit }) {
		const id = props.id;
        const loading = ref(true);
        const overviewModalRef = ref<null | HTMLElement>(null);
		const tableHeader = ref([
            {
                key: "checkbox",
                sortable: false,
            },
            {
                name: "Title",
                key: "title",
                sortable: true,
            },
            {
                name: "Content Type",
                key: "contenttype",
                sortable: true,
            },
            {
                name: "Amount of items",
                key: "itemsamount",
                sortable: true,
            },
            {
                name: "Created",
                key: "created",
                sortable: true,
            },
            {
                name: "Updated",
                key: "updated",
                sortable: true,
            }
        ]);
        
        console.log("test");
        const tableData = ref<Array<IOverview>>([]);
        const initOverview = ref<Array<IOverview>>([]);

        const paginationHeader = ref<any>({ TotalCount: 0 });

        const pagingFilteration = ref<any>({
            paging: {
                pageNumber: 1,
                pageSize: 10,
            },
            filter: {
                searchTerm: "",
                type: "",
            },
            orderBy: {
                orderByField: "",
                orderDesc: false,
            },
        });

		const getOverviewsList = () => {
			loading.value = true;
            ApiService.post(`Overview/list`, pagingFilteration.value)
                .then(({ data, headers }) => {
                    paginationHeader.value = JSON.parse(headers["x-pagination"]);
                    tableData.value.splice(0, tableData.value.length, ...data);
                    initOverview.value.splice(0, tableData.value.length, ...data);
                    loading.value = false;
                    reinitializeComponents();
                })
                .catch(({ response }) => {
                    loading.value = false;
                    tableData.value.splice(0, tableData.value.length);
                    initOverview.value.splice(0, tableData.value.length);
                    console.log("response", response);
                });
		};

		watch(
            () => pagingFilteration.value.paging.pageSize,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.paging.pageNumber,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );

        watch(
            () => pagingFilteration.value.orderBy,
            () => {
                getOverviewsList();
            },
            { deep: true }
        );
            
        const onOrderByChange = (orderQuery) => {
            pagingFilteration.value.orderBy.orderByField = orderQuery.columnName;
            pagingFilteration.value.orderBy.orderDesc = orderQuery.order === "desc";
        };

        const onItemsPerPageChange = (itemsPerPage) => {
            pagingFilteration.value.paging.pageSize = itemsPerPage;
        };

        const onCurrentChange = (currentPage) => {
            pagingFilteration.value.paging.pageNumber = currentPage;
        };

        
		const handleSelectedOverview = row => {
			emit('setSelectedOverview', row);
			hideModal(overviewModalRef.value);
		};

		onMounted(() => {
			getOverviewsList();

			var modelElement = <any>(
				document.getElementById('kt_modal_add_overview_modal')
			);
			if (modelElement) {
				modelElement.addEventListener('hidden.bs.modal', function (event) {
					emit('modalCloseCallback');
				});
			}
		});

        const search = ref<string>("");

        const searchItems = () => {
            getOverviewsList();
        };

		return {
			id,
            loading,
            tableData,
            moment,
            tableHeader,
            getOverviewsList,
            search,
            searchItems,
            onItemsPerPageChange,
            onCurrentChange,
            onOrderByChange,
            pagingFilteration,
            overviewModalRef,
            handleSelectedOverview,
            paginationHeader,
		};
	}
});
