
import { defineComponent, onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { QuillEditor } from '@vueup/vue-quill';
import Swal from 'sweetalert2/dist/sweetalert2.js';
// @import utils
import { IUserLevel } from '@/core/data/userLevel';
import ApiService from '@/core/services/ApiService';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
// @import components
import PageSection from '@/components/page-section/PageSection.vue';
import AddVideoAsset from '@/components/modals/forms/AddVideoAsset.vue';
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import CMSImageListModel from '@/components/modals/forms/CMSImageListModel.vue';

export default defineComponent({
	name: 'Update-Video-Content',
	components: {
		PageSection,
		QuillEditor,
		PlayListModal,
		AddVideoAsset,
		PricePlanModal,
		VideoContentModal,
		CMSImageListModel
	},
	setup() {
		const route = useRoute();
		const store = useStore();

		const imageModel = ref<any>(null);
		const headerIntroTextEditor = ref<any>(null);
		const formRef = ref<null | HTMLFormElement>(null);

		const imageKey = ref('');
		const videoKey = ref('mediaId');
		const activeModalKey = ref('');
		const playListObj = ref<any>({});
		const cmsListObj = ref<any>({});
		const loading = ref<boolean>(false);
		const detailImageUrl = ref<string>('');
		const overviewImageUrl = ref<string>('');
		const selectedPricingPlans = ref<any>([]);
		const userLevel = ref<Array<IUserLevel>>([]);

		const sectionsExpanded = ref<any>([]);

		const isEdit = route.params.id;

		const formData = ref({
			customerId: 0,
			// General Section
			slug: '',
			title: '',
			featuredImage: '',
			// header section
			header: '',
			headerImage: '',
			headerHighlightVideo: '',
			hasHeaderVideoShowLatest: '',
			hasHeaderVideoRandomFromPlaylist: '',
			headerTitle: '',
			headerSubtitle: '',
			headerIntroText: '',
			headerButtonLabel: '',
			headerButtonUrl: '',
			headerSecondButtonLabel: '',
			headerSecondButtonUrl: '',
			// right Section
			id: '',
			created: '',
			updated: '',
			userLevels: '',
			pricingPlans: '',
			public: true,
			published: true,
			freeContent: true,
			// todo: create separate object for section content
			sections: []
		});

		const rules = ref({
			title: [
				{
					required: true,
					message: 'Title is required',
					trigger: 'change'
				}
			],
			slug: [
				{
					required: true,
					message: 'URL is required',
					trigger: 'change'
				}
			]
		});

		const selectedCustomer = computed(() => {
			return store.getters.selectedCustomer;
		});

		onMounted(() => {
			setCurrentPageBreadcrumbs(`${isEdit ? 'Edit' : 'Add'} Page layout`, [
				'Page layout'
			]);
			if (isEdit) {
				getPageById();
			}
			getUserLevels();
		});

		const getPageById = () => {
			ApiService.get(`Page/${route.params.id}`)
				.then(({ data }) => {
					formData.value = {
						...formData.value,
						...data,
						userLevels:
							data.userLevels.indexOf(',') != -1 || data.userLevels != ''
								? data.userLevels.split(',')
								: (data.userLevels = [])
					};

					headerIntroTextEditor.value.setHTML(data.headerIntroText || '');

					getPlayListData(data);
					getThumbnailData(data);

					if (data && data.pricingPlans) {
						getPricePlanList();
					}

					if (data.headerHighlightVideo) {
						getVideoContentById(data.headerHighlightVideo);
					}

					if (data && data.sections) {
						sectionsExpanded.value = data.sectionsExpanded;
					}

					reinitializeComponents();
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getVideoContentById = mId => {
			ApiService.get(`VideoContent/${mId}`)
				.then(({ data }) => {
					setSelectedVideoContent(data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getPlayListDataById = (mId, key) => {
			ApiService.get(`PlayList/${mId}`)
				.then(({ data }) => {
					playListObj.value = {
						...playListObj.value,
						[key]: data
					};
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getPlayListData = playListData => {
			if (playListData.playList1) {
				getPlayListDataById(playListData.playList1, 'playList1');
			}
			if (playListData.playList2) {
				getPlayListDataById(playListData.playList2, 'playList2');
			}
			if (playListData.playList3) {
				getPlayListDataById(playListData.playList3, 'playList3');
			}
		};

		const getCMSListDataById = (mId, key) => {
			ApiService.get(`CmsMedia/${mId}`)
				.then(({ data }) => {
					cmsListObj.value = {
						...cmsListObj.value,
						[key]: data.url
					};
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		const getThumbnailData = data => {
			if (data.featuredImage) {
				getCMSListDataById(data.featuredImage, 'featuredImage');
			}
			if (data.headerImage) {
				getCMSListDataById(data.headerImage, 'headerImage');
			}
		};

		const getUserLevels = () => {
			ApiService.get('UserLevel')
				.then(({ data }) => {
					// console.log('UserLevel response: ', data);
					userLevel.value = data;
				})
				.catch(({ response }) => {
					console.log('response', response);
				});
		};

		const setSelectedPlayList = row => {
			playListObj.value = {
				...playListObj.value,
				[videoKey.value]: row
			};
			formData.value = {
				...formData.value,
				[videoKey.value]: row.id
			};
		};

		const setSelectedVideoAsset = row => {
			playListObj.value = {
				...playListObj.value,
				[videoKey.value]: row
			};
			formData.value = {
				...formData.value,
				[videoKey.value]: row.mediaId
			};
		};

		const setSelectedVideoContent = row => {
			if (activeModalKey.value == 'RelatedContent') {
				// addRelatedContent(row);
			} else {
				playListObj.value = {
					...playListObj.value,
					headerHighlightVideo: row
				};
				formData.value = {
					...formData.value,
					headerHighlightVideo: row.id
				};
			}
		};

		const resetPlayListObject = key => {
			playListObj.value = {
				...playListObj.value,
				[key]: null
			};
			formData.value = {
				...formData.value,
				[key]: ''
			};
		};

		const setSelectedImage = image => {
			console.log('image: ', image);
			console.log('imageKey: ', imageKey.value);
			formData.value = {
				...formData.value,
				[imageKey.value]: image.id
			};

			cmsListObj.value = {
				...cmsListObj.value,
				[imageKey.value]: image.url
			};
		};

		const resetSelectedImage = key => {
			cmsListObj.value = {
				...cmsListObj.value,
				[key]: ''
			};
			formData.value = {
				...formData.value,
				[key]: ''
			};
		};

		const setImageKey = key => {
			imageKey.value = key;
		};

		const setPlayListKey = key => {
			videoKey.value = key;
		};

		const getPricePlanList = () => {
			ApiService.get(`PricePlan`)
				.then(({ data }) => {
					console.log('formData', data, formData.value.pricingPlans);
					const filteredArray =
						data &&
						data.filter(el =>
							formData.value.pricingPlans.split(',').some(item => {
								console.log('loop', item, '--', el.pricePlanId);
								return item == el.pricePlanId;
							})
						);

					selectedPricingPlans.value = filteredArray;
				})
				.catch(({ response }) => {
					console.log('getPricePlanList response error:', response);
					loading.value = false;
				});
		};

		const setSelectedPricePlan = item => {
			// console.log('item:', item);
			selectedPricingPlans.value.push(item);
		};

		const resetPricingPlansObject = itemId => {
			selectedPricingPlans.value = selectedPricingPlans.value.filter(
				e => e.pricePlanId !== itemId
			);
		};

		const submit = () => {
			if (!formRef.value) {
				return;
			}

			if (selectedPricingPlans.value.length) {
				const pricingPlansIds = selectedPricingPlans.value.map(
					item => item.pricePlanId
				);
				formData.value.pricingPlans = pricingPlansIds.join();
			}

			formRef.value.validate(valid => {
				if (valid) {
					loading.value = true;
					const data: any = {
						...formData.value,
						customerId: selectedCustomer.value,
						userLevels: formData.value.userLevels.toString()
					};
					// console.log({ data });
					let serviceType;
					if (isEdit) {
						serviceType = ApiService.put(
							`Page/${route.params.id}?pageId=${route.params?.id}`,
							data
						);
					} else {
						delete data['id'];
						serviceType = ApiService.post('Page', data);
					}

					serviceType
						.then(({ data }) => {
							console.log('create page response', data);
							loading.value = false;
							// redirect to main page ?
							Swal.fire({
								timer: 1500,
								text: 'Form has been successfully submitted!',
								icon: 'success',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							}).then(() => {
								if (isEdit) {
									formData.value = {
										...formData.value,
										...data,
										userLevels:
											data.userLevels.indexOf(',') != -1 ||
											data.userLevels != ''
												? data.userLevels.split(',')
												: (data.userLevels = [])
									};

									if (data && data.sections) {
										sectionsExpanded.value = data.sectionsExpanded;
									}
								} else {
									(
										document.getElementById('formRef') as HTMLFormElement
									).reset();
								}
							});
						})
						.catch(({ response }) => {
							console.log('create page error', response);
							Swal.fire({
								text: 'Sorry, looks like there are some errors detected, please try again.',
								icon: 'error',
								buttonsStyling: false,
								confirmButtonText: 'Ok, got it!',
								customClass: {
									confirmButton: 'btn btn-primary'
								}
							});
						});
				} else {
					Swal.fire({
						text: 'Sorry, looks like there are some errors detected, please try again.',
						icon: 'error',
						buttonsStyling: false,
						confirmButtonText: 'Ok, got it!',
						customClass: {
							confirmButton: 'btn btn-primary'
						}
					});
					return false;
				}
			});
		};

		return {
			rules,
			moment,
			isEdit,
			submit,
			formRef,
			loading,
			formData,
			userLevel,
			imageModel,
			cmsListObj,
			setImageKey,
			playListObj,
			setPlayListKey,
			detailImageUrl,
			setSelectedImage,
			overviewImageUrl,
			resetSelectedImage,
			resetPlayListObject,
			headerIntroTextEditor,
			selectedPricingPlans,
			setSelectedPricePlan,
			resetPricingPlansObject,
			setSelectedPlayList,
			setSelectedVideoAsset,
			setSelectedVideoContent,
			sectionsExpanded
		};
	}
});
