<script setup>
import { useStore } from 'vuex';
import { reinitializeComponents } from '@/core/plugins/keenthemes';
import {
	onMounted,
	defineProps,
	defineEmits,
	ref,
	computed,
	watch,
	onUpdated
} from 'vue';
// @import componnets
import SectionTitle from './sectionTitle.vue';
import SectionSettingsDropdown from './SectionSettingsDropdown.vue';
import ContentSettingsDropdown from './ContentSettingsDropdown.vue';
import PlayListModal from '@/components/modals/forms/PlayListModal.vue';
import PricePlanModal from '@/components/modals/forms/PricePlanModel.vue';
import OverviewModal from '../modals/forms/OverviewModal.vue';
import VideoContentModal from '@/components/modals/forms/VideoContentModal.vue';
import TemplateContentModal from '@/components/modals/forms/TemplateContentModal.vue';
// @import utils
import ApiService from '@/core/services/ApiService';

const store = useStore();

const props = defineProps({
	data: Array,
	expandedData: Array
});

const emit = defineEmits(['callback']);

const pagination = {
	paging: {
		pageNumber: 1,
		pageSize: 100
	},
	filter: {
		searchTerm: ''
	},
	orderBy: {
		orderByField: '',
		orderDesc: false
	}
};

const popupMode = ref('');
const activeContent = ref('');
const activeContentIndex = ref('');
const activeModalKey = ref('');
const activeSectionIndex = ref();
const relatedSectionsArray = ref([]);
const componentListArray = ref([]);
const contentListArray = ref([]);
const cssClasses = ref();
const cssTemplate = ref();
const relatedDropDown = ref({
	selectedvalue: '',
	entityType: '',
	contentType: '',
	contentName: '',
	templateId: '',
	buttonLabel: ''
});

const options = ref({
	// delay: 300,
	draggable: '.draggable',
	handle: '.draggableHandle',
	sortAnimation: {
		duration: 200,
		easingFunction: 'ease-in-out'
	}
	// plugins: [Draggable.Plugins.SortAnimation]
});

const { role } = store.getters.currentUser;

watch(props, to => {
	if (to.data.length) {
		relatedSectionsArray.value = to.data;
	}
});

const getRelatedSectionsArray = computed(() => {
	return relatedSectionsArray.value;
});

const getTitle = content => {
	// console.log('content: ', content);
	if (content.title) return content.title;
	const matchedContent = props.expandedData.find(
		x => x.contentId == content.contentId
	);
	return matchedContent ? matchedContent.title : '';
};

const getTargetedModal = computed(() => {
	if (relatedDropDown.value.entityType == 'VideoContent')
		return '#kt_modal_videocontent_modal';
	if (relatedDropDown.value.entityType == 'Playlist')
		return '#kt_modal_playlist_modal';
	if (relatedDropDown.value.entityType == 'PricePlan')
		return '#kt_modal_priceplan_modal';
	if (relatedDropDown.value.entityType == 'Overview')
		return '#kt_modal_overview_modal';
	if (relatedDropDown.value.entityType == 'TemplateContent')
		return '#kt_modal_content_modal';
});

const getModalType = entityType => {
	if (entityType == 'VideoContent') return '#kt_modal_videocontent_modal';
	if (entityType == 'Playlist') return '#kt_modal_playlist_modal';
	if (entityType == 'PricePlan') return '#kt_modal_priceplan_modal';
	if (entityType == 'Overview') return '#kt_modal_overview_modal';
	if (entityType == 'TemplateContent') return '#kt_modal_content_modal';
};

const getTemplateId = () => {
	if (activeContent.value.contentType == 'Page') {
		const item = contentListArray.value.find(
			x => x.contentName == activeContent.value.contentName
		);
		return item ? item.templateId : '';
	}
	if (activeContent.value.contentType == 'Component') {
		const item = componentListArray.value.find(
			x => x.contentName == activeContent.value.contentName
		).templateId;
		return item ? item : '';
	}
};

onMounted(() => {
	// relatedSectionsArray.value = data;
	getDropdownData();
	getEnums();
});

onUpdated(() => {
	reinitializeComponents();
});

const getDropdownData = async () => {
	// fetch components
	try {
		const componentListAResult = await ApiService.post(
			`Template/list/Component`,
			pagination
		);
		componentListArray.value = componentListAResult.data;
	} catch (error) {
		console.log('getComponentList error response', response);
	}

	// fetch contents
	try {
		const contentListArrayResult = await ApiService.post(
			`Template/list/Page`,
			pagination
		);

		console.log(contentListArrayResult.data);

		contentListArray.value = contentListArrayResult.data;
	} catch (error) {
		console.log('getContentList error response', response);
	}
};

const getEnums = async () => {
	// fetch classes enums
	try {
		const enumValues = await ApiService.get(`EnumerationValues/Nested`);
		enumValues.value = enumValues.data;
		cssClasses.value =
			enumValues.value &&
			enumValues.value.find(item => item.enumName === 'CssClasses');
		cssTemplate.value =
			enumValues.value &&
			enumValues.value.find(item => item.enumName === 'CssTemplate');
	} catch (error) {
		console.log('getEnumValues error response', error);
	}
};

const sorted = (e, index) => {
	relatedSectionsArray.value[index].elements = array_move(
		relatedSectionsArray.value[index].elements,
		e.data.oldIndex,
		e.data.newIndex
	);
	emit('callback', relatedSectionsArray.value);
};

const array_move = (arr, from, to) => {
	return arr.map((item, i) =>
		i === to
			? arr[from]
			: i >= Math.min(from, to) && i <= Math.max(from, to)
			? arr[i + Math.sign(to - from)]
			: item
	);
};

const setModalKey = (mode, index) => {
	popupMode.value = 'add';
	activeContent.value = '';
	activeContentIndex.value = '';
	activeModalKey.value = mode;
	activeSectionIndex.value = index;
};

const setRelatedContentDropdown = item => {
	const splitContent = item.split('||');
	if (splitContent.length > 1) {
		relatedDropDown.value.entityType = 'TemplateContent';
		relatedDropDown.value.contentType = splitContent[0];
		relatedDropDown.value.contentName = splitContent[1];
		relatedDropDown.value.templateId = splitContent[2];
		relatedDropDown.value.buttonLabel = splitContent[0];
	} else {
		relatedDropDown.value.entityType = splitContent[0];
		relatedDropDown.value.contentType = '';
		relatedDropDown.value.contentName = '';
		relatedDropDown.value.templateId = '';
		relatedDropDown.value.buttonLabel = splitContent[0];
	}
};

const addNewSection = () => {
	const data = {
		// id: relatedSectionsArray.value.length + 1,
		key: `${Math.random()}`,
		title: '',
		subtitle: '',
		elements: [],
		settings: {
			class: '',
			template: '',
			sortOrder: 0,
			anchorPoint: ''
		}
	};
	relatedSectionsArray.value.push(data);
};

const addEditRelatedContent = item => {
	console.log('addEditRelatedContent item: ', item);

	let title, contentId;
	if (
		relatedDropDown.value.entityType == 'TemplateContent' ||
		(activeContent.value && activeContent.value.entityType == 'TemplateContent')
	) {
		title = item.templateContentFields.displayTitle;
	} else {
		title = item.title;
	}

	if (
		relatedDropDown.value.entityType == 'PricePlan' ||
		(activeContent.value && activeContent.value.entityType == 'PricePlan')
	) {
		contentId = item.pricePlanId;
	} else {
		contentId = item.id;
	}

	// console.log('item: ', item.templateContentFields.displayTitle);
	const element = {
		entityType: activeContent.value
			? activeContent.value.entityType
			: relatedDropDown.value.entityType,
		contentType: activeContent.value
			? activeContent.value.contentType
			: relatedDropDown.value.contentType,
		contentName: activeContent.value
			? activeContent.value.contentName
			: relatedDropDown.value.contentName,
		contentId: contentId,
		title: title,
		userAccess: activeContent.value ? activeContent.value.userAccess : 0,
		isEditable: activeContent.value ? activeContent.value.isEditable : false,
		isReplacable: activeContent.value ? activeContent.value.isReplacable : false
	};

	if (popupMode.value == 'add') {
		relatedSectionsArray.value[activeSectionIndex.value].elements.push(element);
	}

	if (popupMode.value == 'edit') {
		relatedSectionsArray.value[activeSectionIndex.value].elements[
			activeContentIndex.value
		] = element;
	}

	// reset states
	popupMode.value = '';
	activeContent.value = '';
	activeContentIndex.value = '';

	emit('callback', relatedSectionsArray.value);
};

const editRelatedContent = (content, sectionIndex, contentIndex) => {
	popupMode.value = 'edit';
	activeContent.value = content;
	activeContentIndex.value = contentIndex;
	activeModalKey.value = content.entityType;
	activeSectionIndex.value = sectionIndex;
};

const deleteRelatedContent = (contentId, index) => {
	relatedSectionsArray.value[index].elements = relatedSectionsArray.value[
		index
	].elements.filter(item => item.contentId != contentId);
	emit('callback', relatedSectionsArray.value);
};

const deleteSection = index => {
	relatedSectionsArray.value = relatedSectionsArray.value.filter(
		(item, key) => key != index
	);
	emit('callback', relatedSectionsArray.value);
};

const applySectionSettings = (settingsData, index) => {
	relatedSectionsArray.value[index].settings = settingsData;
};

const handleSectionTitle = (value, index) => {
	relatedSectionsArray.value[index].title = value;
	emit('callback', relatedSectionsArray.value);
};

const handleSectionSubtitle = (value, index) => {
	relatedSectionsArray.value[index].subtitle = value;
	emit('callback', relatedSectionsArray.value);
};
</script>

<template>
	<!--begin:: Related Content Card-->
	<pre class="d-none"
		>{{ getRelatedSectionsArray }}, {{ contentListArray }}, {{
			componentListArray
		}}</pre
	>

	<!-- begin:: Sections -->
	<div
		v-for="(section, index) in getRelatedSectionsArray"
		:key="`${section.id || section.key}`"
		class="card mt-5"
	>
		<vue-sortable :options="options" @sortable:stop="sorted" :key="Math.random()">
			<div class="card-header align-items-center justify-content-between border-0">
				<h2 class="fw-bolder m-0">Section</h2>

				<div v-if="role.toLowerCase() === 'admin'" class="card-toolbar">
					<button
						type="button"
						class="btn btn-sm btn-icon btn-color-gray btn-light btn-active-light-primary"
						data-kt-menu-trigger="click"
						data-kt-menu-placement="bottom-end"
						data-kt-menu-flip="top-end"
					>
						<span class="svg-icon svg-icon-2">
							<!-- gen023 -->
							<inline-svg src="media/icons/duotune/general/gen052.svg" />
						</span>
					</button>
					<SectionSettingsDropdown
						:settingsData="{
							anchorPoint: section.settings.anchorPoint,
							class: section.settings.class,
							template: section.settings.template,
							sortOrder: section.settings.sortOrder,
							cssClasses: cssClasses,
							cssTemplate: cssTemplate
						}"
						@applySectionSettings="
							settingsData => applySectionSettings(settingsData, index)
						"
						@deleteSection="deleteSection(index)"
					/>
				</div>
			</div>

			<div class="card-body pt-4">
				<!--begin::Input group-->
				<SectionTitle
					:value="relatedSectionsArray[index].title"
					displayTitle="Title"
					@callback="value => handleSectionTitle(value, index)"
				/>
				<!--end::Input group-->

				<!--begin::Input group-->
				<SectionTitle
					:value="relatedSectionsArray[index].subtitle"
					displayTitle="Subtitle"
					@callback="value => handleSectionSubtitle(value, index)"
				/>
				<!--end::Input group-->

				<!-- begin:: list of selected content -->
				<vue-draggable-container tag="ul" class="ps-1 m-0">
					<li
						:key="`content_${contentIndex}`"
						v-for="(content, contentIndex) in section.elements"
						class="draggable card border border-primary mb-5"
						:class="{
							'mb-10': section.elements.length == contentIndex + 1
						}"
					>
						<span
							style="top: 8px; left: -20px; cursor: grab"
							:class="{ 'd-none': role.toLowerCase() !== 'admin' }"
							class="draggableHandle svg-icon svg-icon-7 svg-icon-primary position-absolute"
						>
							<inline-svg
								class="w-100"
								style="transform: rotate(45deg)"
								src="/media/icons/duotune/arrows/arr035.svg"
							/>
						</span>

						<div
							class="card-body p-5 d-flex justify-content-between align-items-center"
						>
							<div class="d-flex flex-column">
								<strong v-if="getTitle(content)"
									>Title: {{ getTitle(content) }}</strong
								>
								<span class="text-muted"
									>Entity type: {{ content.entityType }}</span
								>
								<div class="d-flex mt-1" v-if="content.contentType">
									<span class="text-muted me-10"
										>contentName: {{ content.contentName }}</span
									>
									<span class="text-muted me-10"
										>ContentType: {{ content.contentType }}</span
									>
								</div>
								<div class="d-flex mt-1">
									<span class="text-muted me-10"
										>ID: {{ content.contentId }}</span
									>
									<span class="text-success"
										>User Access:
										{{ content.userAccess == 1 ? 'Public' : 'Private' }}</span
									>
								</div>
							</div>

							<div class="d-flex">
								<button
									type="button"
									data-bs-toggle="modal"
									v-if="
										role.toLowerCase() == 'admin' ||
										(role.toLowerCase() != 'admin' && content.isEditable)
									"
									:data-bs-target="getModalType(content.entityType)"
									@click="editRelatedContent(content, index, contentIndex)"
									class="btn btn-sm btn-icon btn-color-gray btn-white btn-active-light-primary me-2"
								>
									<span
										class="d-inline-block svg-icon svg-icon-2 svg-icon-primary align-text-bottom"
									>
										<inline-svg src="media/icons/duotune/art/art005.svg" />
									</span>
								</button>

								<template v-if="role.toLowerCase() === 'admin'">
									<button
										type="button"
										@click="deleteRelatedContent(content.contentId, index)"
										class="btn btn-sm btn-icon btn-color-gray btn-white btn-active-light-primary me-2"
									>
										<span class="svg-icon svg-icon-2">
											<inline-svg
												src="media/icons/duotune/general/gen027.svg"
											/>
										</span>
									</button>

									<button
										type="button"
										class="btn btn-sm btn-icon btn-color-gray btn-white btn-active-light-primary"
										data-kt-menu-trigger="click"
										data-kt-menu-placement="bottom-end"
										data-kt-menu-flip="top-end"
									>
										<span class="svg-icon svg-icon-2">
											<inline-svg
												src="media/icons/duotune/general/gen019.svg"
											/>
										</span>
									</button>
									<ContentSettingsDropdown
										:userAccess="content.userAccess"
										:isEditable="content.isEditable"
										:isReplacable="content.isReplacable"
										@applyContentSettings="
											contentSetting => {
												content.userAccess = contentSetting.userAccess;
												content.isEditable = contentSetting.isEditable;
												content.isReplacable = contentSetting.isReplacable;
											}
										"
									/>
								</template>
							</div>
						</div>
					</li>
					<!-- end:: list of selected content -->
				</vue-draggable-container>
				<!-- end:: list of selected content -->

				<!-- begin:: Add new content card -->
				<div v-if="role.toLowerCase() === 'admin'" class="card outlined-card">
					<div class="card-body">
						<h3 class="fw-bold mb-10">Add new content</h3>
						<div class="fv-row d-flex flex-column">
							<label class="required fs-6 fw-bold mb-2"
								>Select content type</label
							>
							<el-select
								filterable
								class="w-100"
								default-first-option
								id="relatedContentDropdown"
								placeholder="Select an option..."
								v-model="relatedDropDown.selectedvalue"
								@change="setRelatedContentDropdown"
							>
								<el-option-group label="">
									<el-option label="VideoContent" value="VideoContent" />
									<el-option label="Playlist" value="Playlist" />
									<el-option label="Overview" value="Overview" />
									<el-option label="PricePlan" value="PricePlan" />
								</el-option-group>
								<el-option-group label="Content" v-if="contentListArray && contentListArray.length > 0">
									<el-option
										v-for="(item, ind) in contentListArray"
										:key="`content_${ind}`"
										:label="item.contentName"
										:value="`${item.contentType}||${item.contentName}||${item.templateId}`"
									/>
								</el-option-group>
								<el-option-group label="Component" v-if="componentListArray && componentListArray.length > 0">
									<el-option
										v-for="(item, ind) in componentListArray"
										:key="`component_${ind}`"
										:label="item.contentName"
										:value="`${item.contentType}||${item.contentName}||${item.templateId}`"
									/>
								</el-option-group>
							</el-select>

							<button
								type="button"
								data-bs-toggle="modal"
								:data-bs-target="getTargetedModal"
								@click="setModalKey(relatedDropDown.entityType, index)"
								v-if="relatedDropDown.entityType"
								:class="`btn btn-outline btn-outline-primary bg-light-primary mt-4 me-auto`"
							>
								<span
									class="d-inline-block svg-icon svg-icon-2 svg-icon-primary align-text-bottom"
								>
									<inline-svg src="media/icons/duotune/general/gen035.svg" />
								</span>
								Select {{ relatedDropDown.buttonLabel }}
							</button>
						</div>
					</div>
				</div>
				<!-- end:: Add new content card -->
			</div>
		</vue-sortable>
	</div>
	<!-- end:: Sections -->

	<!--begin::Add new section Card-->
	<div v-if="role.toLowerCase() === 'admin'" class="card mt-5 outlined-card">
		<div class="card-body d-flex align-items-center justify-content-center">
			<button
				type="button"
				@click="addNewSection()"
				class="btn btn-outline btn-outline-primary bg-light-primary"
			>
				Add new section
			</button>
		</div>
	</div>
	<!--end::Add new section Card-->

	<PlayListModal
		id="kt_modal_playlist_modal"
		@setSelectedPlayList="addEditRelatedContent"
	/>
	<PricePlanModal
		id="kt_modal_priceplan_modal"
		@setSelectedPricePlan="addEditRelatedContent"
	/>
	<OverviewModal
		id="kt_modal_overview_modal"
		@setSelectedOverview="addEditRelatedContent"
	/>
	<VideoContentModal
		id="kt_modal_videocontent_modal"
		@setSelectedVideoContent="addEditRelatedContent"
	/>
	<TemplateContentModal
		id="kt_modal_content_modal"
		:templateType="
			popupMode == 'add'
				? relatedDropDown.contentName
				: activeContent.contentName
		"
		:templateId="
			popupMode == 'add' ? relatedDropDown.templateId : getTemplateId()
		"
		@callback="addEditRelatedContent"
	/>
</template>
