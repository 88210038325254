
import { defineComponent, ref } from 'vue';

interface ContentSettingsInterface {
	userAccess: Number;
	isEditable: boolean;
	isReplacable: boolean;
}

export default defineComponent({
	name: 'ContentSettingsDropdown',
	components: {},
	props: ['userAccess', 'isEditable', 'isReplacable'],
	setup(props, { emit }) {
		const data = ref<ContentSettingsInterface>({
			userAccess: props.userAccess || 0,
			isEditable: props.isEditable || false,
			isReplacable: props.isReplacable || false
		});

		const applySettings = () => {
			emit('applyContentSettings', data.value);
		};

		return {
			data,
			applySettings
		};
	}
});
