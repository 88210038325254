
import { defineComponent, ref } from 'vue';

interface SectionTitleInterface {
	value: String;
	displayTitle: String;
}

export default defineComponent({
	name: 'value',
	components: {},
	props: ['value', 'displayTitle'],
	setup(props, { emit }) {
		const data = ref<SectionTitleInterface>({
			value: props.value || '',
			displayTitle: props.displayTitle || ''
		});

		const callback = () => {
			emit('callback', data.value.value);
		};

		return {
			data,
			callback
		};
	}
});
