import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fv-row mb-7" }
const _hoisted_2 = { class: "fs-6 fw-bold mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.displayTitle), 1),
    _createVNode(_component_el_form_item, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_input, {
          type: "text",
          placeholder: _ctx.displayTitle,
          modelValue: _ctx.data.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.value) = $event)),
          onChange: _ctx.callback
        }, null, 8, ["placeholder", "modelValue", "onChange"])
      ]),
      _: 1
    })
  ]))
}